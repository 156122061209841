<template>
  <div class="project top-fix">
    <van-nav-bar title="项目管理" fixed left-arrow @click-left="$router.back(-1)" />
    <van-cell-group>
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label"><span>条码</span></div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <input type="text" placeholder="请输入" ref="barcode" v-model="barcode" class="van-field__control" @keyup.enter="changeBarcode" @focus="focusBarcode" @blur="changeReadonly('barcode')" :readonly="readonly.barcode" />
            <van-icon name="scan" @click="scan" />
          </div>
        </div>
      </div>
    </van-cell-group>
    <div class="task_info" v-if="taskInfo">
      <van-cell-group inset>
        <van-row class="item">
          <van-col span="24">
            <van-cell title="项目号" :value="taskInfo.pno" />
          </van-col>
        </van-row>
        <van-row class="item">
          <van-col span="24">
            <van-cell title="项目地点" :value="taskInfo.location" />
          </van-col>
        </van-row>
        <van-row class="item">
          <van-col span="24">
            <van-cell title="客户" :value="taskInfo.cus" />
          </van-col>
        </van-row>
        <van-row class="item">
          <van-col span="24">
            <van-cell title="任务号" :value="taskInfo.tno" />
          </van-col>
        </van-row>
        <van-row class="item">
          <van-col span="24">
            <van-cell title="描述" :value="taskInfo.describe" />
          </van-col>
        </van-row>
        <van-row class="item">
          <van-col span="24">
            <van-cell title="状态" :value="dd.taskstatus[taskInfo.status]" />
          </van-col>
        </van-row>
        <van-row class="item" v-if="imgs.length>0">
          <van-image class="img" v-for="(item, index) in imgs" :key="index"
            width="100"
            height="100"
            :src="item" />
        </van-row>
        <div class="report" v-if="taskInfo.status==10">
          <van-button type="info" @click="popupShow()">报工</van-button>
        </div>
      </van-cell-group>
    </div>
    <van-popup v-model="popup" closeable class="finish_task">
      <van-field
        v-model="explain"
        rows="5"
        autosize
        label="任务说明"
        type="textarea"
        placeholder="请输入任务说明" />
      <van-uploader v-model="fileList" multiple :after-read="afterRead" :max-count="9" ref="file" />
      <div class="submit">
        <van-button type="info" size="small " @click="submit()">提交</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import TaskApi from "@/api/task.js";
import axios from "axios";
import { Dialog } from "vant";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      barcode: "",
      taskInfo: false,
      popup: false,
      fileList: [],
      explain: "",
      readonly: {
        barcode: true
      },
      finishUpload: true
    };
  },
  computed: {
    ...mapState({
      dd: state => state.dd.dd
    }),
    imgs() {
      let imgArr = [];
      if (this.taskInfo && this.taskInfo.picture) {
        let picArr = JSON.parse(this.taskInfo.picture);
        picArr.map(element => {
          imgArr.push(process.env.VUE_APP_IMG + element);
        });
      }
      return imgArr;
    }
  },
  methods: {
    changeReadonly: function(name) {
      var obj = this.readonly;
      if (obj[name]) {
        setTimeout(() => {
          obj[name] = !obj[name];
        }, 200);
      } else {
        obj[name] = !obj[name];
      }
    },
    focusBarcode: function() {
      this.barcode = "";
      this.taskInfo = false;
      this.popup = false;
      this.fileList = [];
      this.explain = "";
      this.changeReadonly("barcode");
    },
    changeBarcode() {
      setTimeout(() => {
        this.getTaskInfo();
      }, 100);
    },
    getTaskInfo() {
      let barcodeArr = this.barcode.split("&");
      TaskApi.taskinfo(barcodeArr[1], barcodeArr[0])
        .then(res => {
          this.taskInfo = res.data.info;
          this.$refs.barcode.blur();
        })
        .catch(e => {
          this.taskInfo = false;
        });
    },
    popupShow() {
      this.popup = true;
    },
    scan() {
      this.$router.replace({
        name: "Qrcode",
        params: { from: "Project" }
      });
    },
    afterRead(file) {
      let param = new FormData();
      if (Array.isArray(file)) {
        file.map(element => {
          param.append("file[]", element.file);
        });
      } else {
        param.append("file", file.file);
      }
      let url = "uploadfile";
      let config = {
        headers: { "Content-Type": "multipart/form-data" }
      }; //添加请求头
      this.finishUpload = false;
      axios
        .post(process.env.VUE_APP_API + url, param, config)
        .then(response => {
          let res = response.data;
          if (res.code == 200) {
            if (Array.isArray(file)) {
              file.map((element, index) => {
                element.status = "success";
                element.message = "";
                element.url = res.data.path[index];
              });
            } else {
              file.url = res.data.path[0];
            }
          }
          this.finishUpload = true;
        })
        .catch(() => {
          this.finishUpload = true;
        });
    },
    submit() {
      if (this.fileList.length <= 0) {
        Dialog.alert({
          title: "错误",
          message: "请上传现场图片"
        });
        return;
      }
      if (this.finishUpload == false) {
        Dialog.alert({
          title: "错误",
          message: "图片上传中，请稍后"
        });
        return;
      }
      //图片路径
      let files = [];
      this.fileList.map(element => {
        files.push(element.url);
      });
      let fileStr = JSON.stringify(files);

      TaskApi.finishtask(this.taskInfo.id, this.explain, fileStr).then(res => {
        this.focusBarcode();
      });
    }
  },
  created: function() {
    if (Object.prototype.hasOwnProperty.call(this.$route.params, "qrcode")) {
      this.barcode = this.$route.params.qrcode;
      this.changeBarcode();
    }
  }
};
</script>

<style lang="scss">
.project {
  padding: 50px 0 70px;
  .task_info {
    background: #f7f8fc;
    padding: 10px 0;
    .title {
      font-size: 16px;
      text-align: center;
      margin: 5px 0;
    }
    .info {
      padding: 0 10px;
      > div {
        text-align: center;
        margin: 3px 0;
      }
      > div:first-child {
        text-align: left;
      }
      > div:last-child {
        text-align: right;
      }
    }
  }
  .item {
    .van-cell__title {
      flex: none;
      width: 7.2em;
    }
    .van-cell__value {
      text-align: left;
    }
    .red {
      color: #f00;
    }
    .blue {
      color: rgb(0, 47, 255);
    }
    .img {
      margin: 1%;
    }
  }
  .report {
    text-align: center;
    padding: 10px;
  }
  .finish_task {
    height: 80%;
    width: 80%;
    padding: 10px;
    .van-uploader {
      margin: 10px 0;
    }
    .submit {
      text-align: center;
      padding: 10px;
    }
  }
}
</style>
